
<script>
import BackArrow from "../BackArrow";
import { Container } from "./styled";
export default {
  props: {
    headerProps: {
      title: String,
      btns: {
        type: Array,
        default: {
          text: "",
          color: "",
          action: () => {},
          icon: "",
          path: "",
          isRedirector: false,
        },
      },
    },
  },
  components: {
    Container,
    BackArrow,
  },
};
</script>
