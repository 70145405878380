<script>
import { Container } from "./styled";
export default {
  props: {
    search: String,
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: { Container },
  methods: {
    handleSearch(value) {
      this.$emit("handleSearch", value);
    },
  },
};
</script>
