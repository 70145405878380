<script>
import GlobalToolbar from "@/components/GlobalToolbar";
import SearchBar from "@/components/SearchBar";
export default {
  components: { GlobalToolbar, SearchBar },
  props: {
    search: String,
    items: {
      type: Array,
      default: () => [],
    },
    headerProps: {
      title: String,
      btns: {
        type: Array,
        default: {
          text: "",
          color: "",
          action: () => {},
          icon: "",
          path: "",
          isRedirector: false,
        },
      },
    },
  },
  methods: {
    handleSearch(value) {
      this.$emit("handleSearch", value);
    },
  },
};
</script>
