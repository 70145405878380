<script>
import SubHeader from "@/components/SubHeader";
export default {
  components: {
    SubHeader,
  },
  data: () => ({
    headerProps: {
      title: "Gerenciar Reaproveitamento de Trechos",
      btns: [
        {
          text: "Novo",
          color: "primary",
          action: () => {},
          icon: "mdi-plus",
          path: "/cadastros-operacionais/rtrechos/new",
          isRedirector: true,
        },
        {
          text: "Recarregar",
          color: "primary",
          action: () => {},
          icon: "mdi-update",
          path: "",
          isRedirector: false,
        },
      ],
    },
    dialog: false,
    search: "",
    headers: [
      { text: "Linha", value: "linha.descricao", sortable: false },
      { text: "Origem Venda", value: "origem_venda.nome", sortable: false },
      { text: "Destino Venda", value: "destino_venda.nome", sortable: false },
      { text: "Origem impressao", value: "origem_imp.nome", sortable: false },
      { text: "Destino impressao", value: "destino_imp.nome", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
  }),
  created() {
    this.initialize();
    this.headerProps.btns[1].action = () => this.initialize();
  },

  methods: {
    handleSearch: function (value) {
      this.search = value;
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.$http.get("/reaproveitamento").then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    editar(id) {
      this.$router.push(`/cadastros-operacionais/rtrechos/editar/${id}`);
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/reaproveitamento/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.initialize();
        });
      }
    },
  },
};
</script>
